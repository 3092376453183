import { render, staticRenderFns } from "./SimplifiedPESI.vue?vue&type=template&id=bc55a1ea&scoped=true"
import script from "./SimplifiedPESI.vue?vue&type=script&lang=js"
export * from "./SimplifiedPESI.vue?vue&type=script&lang=js"
import style0 from "./SimplifiedPESI.vue?vue&type=style&index=0&id=bc55a1ea&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc55a1ea",
  null
  
)

export default component.exports